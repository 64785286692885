<template lang="pug">
 blade-box.vuetable-overscroll
  template(slot="header")
   h2
    font-awesome-icon(icon="list")
    | {{ " " + $t('cloud_classroom') + "  -  " + this.$t(currentSubTitle + '_class') }}
  template(slot="body")
    transition(name='fade')
      cloud-classroom-filter(v-if="currentType === 3" @search="filterSearch")
    .panel.panel-default(align="left")
      h3.time-text(align="right")
        server-time
      b-nav.mb-4(tabs)
        b-nav-item(:active='currentType === 1' @click="onChangeType(1)") {{ $t('present_class') }}
        b-nav-item(:active='currentType === 2' @click="onChangeType(2)") {{ $t('today_class') }}
        b-nav-item(:active='currentType === 3' @click="onChangeType(3)") {{ $t('history_class') }}
      auto-refresh(ref="auto-refresh" v-if="currentType !== 3" @switchAutoLoad="switchAutoLoad")
      shorthand-description
      cloud-classroom-table(
        :currentType="getCurrentType(currentType)"
        :classroomData="classroomData"
        @tableControl="tableControl"
      )
</template>

<script type="text/javascript">
import moment from 'moment';
import LioshutanApi from '@/api';
import BladeBox from '@/components/bladeBox';
import CloudClassroomFilter from '@/components/consultant/cloudClassroom/cloudClassroomFilter';
import CloudClassroomTable from '@/components/consultant/cloudClassroom/cloudClassroomTable';
import AutoRefresh from '@/components/consultant/cloudClassroom/autoRefresh';
import ShorthandDescription from '@/components/consultant/cloudClassroom/shorthandDescription';
import serverTime from '@/components/serverTimeClock/serveTime';
import { classroomLang } from './lang';
import WuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';

export default {
  name: 'CloudClassroom',

  components: {
    BladeBox,
    CloudClassroomFilter,
    CloudClassroomTable,
    AutoRefresh,
    ShorthandDescription,
    serverTime,
  },
  mixins: [WuwowLoadingMixin],

  i18n: classroomLang,

  data() {
    return {
      currentType: 1,
      autoLoadTimer: '',
      classroomData: {},
      dataParams: {
        st: 'cloud_classroom',
        ac: 'present',
        per_page: 10,
        page: 1,
      },
    };
  },

  computed: {
    // 副標題
    currentSubTitle(){
      return this.getCurrentType(this.currentType);
    },
  },

  mounted(){
    this.switchAutoLoad(true);
  },

  beforeDestroy(){
    clearInterval(this.autoLoadTimer);
  },

  methods: {
    getCurrentType(timeSlot) {
      switch (timeSlot) {
        case 1:
          return 'present';
        case 2:
          return 'today';
        case 3:
          return 'history';
      }
    },

    // 接收 auto-refresh的值控制是否開啟
    switchAutoLoad(value){
      if (value){
        clearInterval(this.autoLoadTimer);
        this.autoLoadTimer = setInterval(() => {
          this.getConsultantClassroomData();
        }, 30000);
      } else {
        clearInterval(this.autoLoadTimer);
      }
    },

    onChangeType(type){
      this.currentType = type;
      this.typeChangeParamsControl();
      this.getConsultantClassroomData();
      // 若為history查詢關閉auto-refresh
      if (type === 3){
        clearInterval(this.autoLoadTimer);
      } else {
        this.switchAutoLoad(true);
      }
    },

    async getConsultantClassroomData(){
      const tag = this.startLoading();
      const res = await LioshutanApi
        .classroom.getConsultantClassroom(this.dataParams);
      // table資料
      // this.classroomData = res;
      this.classroomData = {
        data: res.data,
        pagination: res.meta.pagination,
      };
      this.endLoading(tag);
    },

    // 控制 Filter 傳入所有資料
    filterSearch(chidFilter){
      if (JSON.stringify(chidFilter) !== '{}'){
        const classStartDate = moment(chidFilter.classStartDate).format('YYYY-MM-DD');
        const classEndDate = moment(chidFilter.classEndDate).format('YYYY-MM-DD');
        this.dataParams.cts = classStartDate + ' ' + chidFilter.classStartTime.HH + ':' + chidFilter.classStartTime.mm + ':00';
        this.dataParams.cte = classEndDate + ' ' + chidFilter.classEndTime.HH + ':' + chidFilter.classEndTime.mm + ':00';
      } else {
        delete this.dataParams.cts;
        delete this.dataParams.cte;
      }
      // 回到第一頁
      this.dataParams.page = 1;
      this.getConsultantClassroomData();
    },

    // 控制 table換頁、排序後進行查詢
    tableControl(params){
      // 畫面進入第一次時會先執行一次 (由vuetable data-manager 觸發)
      this.dataParams.page = params.pageInfo.current_page;
      this.dataParams.sort = params.sort.length > 0
        ? params.sort[0].sortField + '|' + params.sort[0].direction
        : 'class_time|asc';
      this.getConsultantClassroomData();
    },

    typeChangeParamsControl(){
      // 更改查詢的type
      this.dataParams.ac = this.getCurrentType(this.currentType);
      delete this.dataParams.cts;
      delete this.dataParams.cte;
    },
  },
};
</script>

<style lang="scss">
@import "./classroom";
</style>

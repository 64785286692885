<template>
  <div :class="'card card-accent-' + type">
    <div class="card-header">
      <slot name="header" />
    </div>
    <div class="card-body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Box',

  props: {
    // Display style for box: primary, danger, success, warning, info.
    // Note: These styles are not supported by Bootstrap natively.
    //       Instead, they're defined by AdminLTE's css.
    type: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

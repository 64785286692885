var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('blade-box', {
    staticClass: "vuetable-overscroll"
  }, [_c('template', {
    slot: "header"
  }, [_c('h2', [_c('font-awesome-icon', {
    attrs: {
      "icon": "list"
    }
  }), _vm._v(_vm._s(" " + _vm.$t('cloud_classroom') + " - " + this.$t(_vm.currentSubTitle + '_class')))], 1)]), _c('template', {
    slot: "body"
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.currentType === 3 ? _c('cloud-classroom-filter', {
    on: {
      "search": _vm.filterSearch
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "panel panel-default",
    attrs: {
      "align": "left"
    }
  }, [_c('h3', {
    staticClass: "time-text",
    attrs: {
      "align": "right"
    }
  }, [_c('server-time')], 1), _c('b-nav', {
    staticClass: "mb-4",
    attrs: {
      "tabs": ""
    }
  }, [_c('b-nav-item', {
    attrs: {
      "active": _vm.currentType === 1
    },
    on: {
      "click": function ($event) {
        return _vm.onChangeType(1);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('present_class')))]), _c('b-nav-item', {
    attrs: {
      "active": _vm.currentType === 2
    },
    on: {
      "click": function ($event) {
        return _vm.onChangeType(2);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('today_class')))]), _c('b-nav-item', {
    attrs: {
      "active": _vm.currentType === 3
    },
    on: {
      "click": function ($event) {
        return _vm.onChangeType(3);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('history_class')))])], 1), _vm.currentType !== 3 ? _c('auto-refresh', {
    ref: "auto-refresh",
    on: {
      "switchAutoLoad": _vm.switchAutoLoad
    }
  }) : _vm._e(), _c('shorthand-description'), _c('cloud-classroom-table', {
    attrs: {
      "currentType": _vm.getCurrentType(_vm.currentType),
      "classroomData": _vm.classroomData
    },
    on: {
      "tableControl": _vm.tableControl
    }
  })], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
const autoRefreshLang = {
  messages: {
    tw: {
      'auto_refresh': '自動刷新畫面',
    },
    en: {
      'auto_refresh': 'Auto Refresh',
    },
  },
};

export {
  autoRefreshLang
};

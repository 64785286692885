<template lang="pug">
b-modal(
  v-model="show"
  size="lg"
  centered
  hide-header
  hide-footer
  @change="initData"
)
  template(v-if="payload")
    template(v-if="page === PAGE.HISTORY")
      h1.text-center {{ `${payload.vip.english_name}(${payload.vip.chinese_name})` }}
      table.w-100
        thead
          tr
            th Consultant
            th Material
            //- th Vocabulary
            //- th Grammar
            //- th Listening
            //- th Speaking
            //- th Reading
            //- th Pronunciation
            th Average
            th Upgrade
            th Operation
        tbody
          tr(v-for="(data, index) in history" :key="index")
            td {{ `${data.consultant.english_name}` }}
            td {{ data.material.title }}
            //- td {{ `LV. ${data.vocabulary_level}` }}
            //- td {{ `LV. ${data.grammar_level}` }}
            //- td {{ `LV. ${data.listening_level}` }}
            //- td {{ `LV. ${data.speaking_level}` }}
            //- td {{ `LV. ${data.reading_level}` }}
            //- td {{ `LV. ${data.pronunciation_level}` }}
            td {{ `LV. ${data.average_level}` }}
            td {{ `LV. ${data.upgrade_level}` }}
            td
              button.btn.btn-link.text-primary.p-0(
                @click="showInfo(data)"
              ) more...
    template(v-if="page === PAGE.INFO")
      button.btn.btn-link.text-primary.p-0(
        style="top: -30px; left: 0;"
        @click="page = 'history'"
      ) < return to history
      .container.bg-white.rounded-bottom
        .row(style="background-color: #f3f1ed;")
          .col
            radar-chart.my-5(
              v-if="!isLoading"
              ref="chart"
              :chart-data="analysis"
              :options="options"
            )
        .row
          .col.bg-blue.rounded-top.py-2
            .d-flex.justify-content-center
              span.text-white.h3
                | {{ material }}
        .row.border-dark.py-4
          .col-md-3.d-flex.justify-content-center
            font-awesome-icon(icon="calendar-minus" size="lg").mr-2
            | {{ createdAt }}
          .col-md-3.d-flex.justify-content-center
            font-awesome-icon(icon="user" size="lg").mr-2
            | {{ name.consultant }}
          .col-md-3.d-flex.justify-content-center
            font-awesome-icon(icon="users" size="lg").mr-2
            | {{ name.vip }}
          .col-md-3.d-flex.justify-content-center
            font-awesome-icon(icon="chart-line" size="lg").mr-2
            | Lv. {{ averageLevel }}
        .row
          .col
            textarea(
              v-model="comment.recommend"
              placeholder="Suggestions for improvement."
              :rows="6"
            ).w-100.mt-1.mb-3
</template>

<script>
/* eslint-disable */
import Comment from '@/components/memberClassRecords/viewAnalysis/comment/index.vue';
import RadarChart from '@/components/memberClassRecords/viewAnalysis/radarChart/index.vue';

import api, { Platform } from '@lioshutan/api-package';

import { v4 } from 'uuid';
// import sweetalert from 'sweetalert2';

import modalMixin from '@/mixins/modal';
import { mapState } from 'vuex';
import axios from 'axios';

export const uuid = v4();

const PAGE = {
  HISTORY: 'history',
  INFO: 'info',
};

export default {
  components: {
    Comment,
    RadarChart,
  },
  mixins: [
    modalMixin,
  ],
  data: () => ({

    classApplicationId: 0,

    isLoading: false,
    averageLevel: 0,
    createdAt: null,
    material: '',
    name: {
      vip: '',
      consultant: '',
    },
    comment: {
      vocabulary: '',
      listening: '',
      grammar: '',
      speaking: '',
      reading: '',
      pronunciation: '',
      recommend: '',
    },
    level: {
      vocabulary: 0,
      listening: 0,
      grammar: 0,
      speaking: 0,
      reading: 0,
      pronunciation: 0,
    },
    analysis: null,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
      scale: {
        ticks: {
          //TODO 需要配合 api 提供產品的 min , max 等級 (wuwow 1-12 , jr 0-6) By Dobby
          min: 0,
          max: 6,
        },
        pointLabels: {
          fontSize: 16,
          fontFamily: 'Noto Sans TC,sans-serif',
        },
      },
      legend: {
        labels: {
          fontSize: 16,
          fontFamily: 'Noto Sans TC,sans-serif',
        },
      },
    },

    PAGE,
    history: [],
    targetInfo: null,
    page: PAGE.HISTORY,
  }),
  computed: {
    ...mapState({
      token: (state) => state.auth.token,
      payload: (state) => state.modal.payload[uuid],
    }),
  },
  watch: {
    async payload() {
      if (!this.payload) {
        return;
      }
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_API}/v3/taichi-external/class-record/get-last-twenty-vip-analysis`,
        {
          params: {
            platform: 1,
            vipId: this.payload.vip.id,
            limit: 20,
          },
          headers: { Authorization: `Bearer ${this.token}` },
        }
      );

      this.history = response.data.data;
    },
  },
  created() {
    this.initModal(uuid, {
      status: false,
    });
  },
  methods: {
    initData() {
      this.page = PAGE.HISTORY;
      this.targetInfo = null;
      this.history = [];
      this.close();
    },
    async getData() {
      const {
        data,
      } = await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .analysis(this.token)
        .consultantGetStudentAnalysis({
          platform: Platform.WUWOW_JUNIOR,
          vipId: this.targetInfo.vip_id,
          classApplicationId: this.targetInfo.ca_id,
        });

      this.analysis = {
        labels: [
          'Vocabulary',
          'Grammar',
          'Listening',
          'Speaking',
          'Reading',
          'Pronunciation',
        ],
        datasets: [
          {
            data: [
              data.vocabularyLevel,
              data.grammarLevel,
              data.listeningLevel,
              data.speakingLevel,
              data.readingLevel,
              data.pronunciationLevel,
            ],
            label: 'level',
            backgroundColor: '#4085c0',
          },
        ],
      };

      this.material = data.material.title;

      this.name.vip = data.vip.englishName;
      this.name.consultant = data.consultant.englishName;

      this.comment.vocabulary = data.suggestionOfVocabulary;
      this.comment.listening = data.suggestionOfListening;
      this.comment.grammar = data.suggestionOfGrammar;
      this.comment.speaking = data.suggestionOfSpeaking;
      this.comment.reading = data.suggestionOfReading;
      this.comment.pronunciation = data.suggestionOfPronunciation;
      this.comment.recommend = data.suggestionOfConsultant;

      this.level.vocabulary = data.vocabularyLevel;
      this.level.grammar = data.grammarLevel;
      this.level.listening = data.listeningLevel;
      this.level.speaking = data.speakingLevel;
      this.level.reading = data.readingLevel;
      this.level.pronunciation = data.pronunciationLevel;

      this.averageLevel = data.averageLevel;
      this.createdAt = data.createdAt;
    },
    async showInfo(data) {
      this.targetInfo = data;
      await this.getData();
      this.page = 'info';
    },
  },
};
</script>

<style scoped>
.text-primary {
  color: #003399 !important;
}
</style>

<template lang="pug">
  div(align="right")
    span.auto-refresh-text {{ $t('auto_refresh') }}：
    vue-switch(v-model="autoRefresh" type="text" variant="success" pill on="On" off="Off")
</template>

<script type="text/javascript">
import VueSwitch from '@/components/switch';
import { autoRefreshLang } from './lang';

export default {
  name: 'AutoRefresh',

  components: {
    VueSwitch,
  },

  i18n: autoRefreshLang,

  data() {
    return {
      autoRefresh: true,
    };
  },

  watch: {
    autoRefresh(val){
      this.$emit('switchAutoLoad', val);
    },
  },

  methods: {
  },
};
</script>

<style lang="scss">
@import "./autoRefresh";
</style>

const cloudClassroomFilterLang = {
  messages: {
    tw: {
      'class_start_time': '教室開始時間',
      'class_end_time': '教室結束時間',
      'select_date': '選擇時間',
      'go': '搜尋',
      'reset': '重設',
      'inputField': '欄位不能為空白',
    },
    en: {
      'class_start_time': 'Class Start Time',
      'class_end_time': 'Class End Time',
      'select_date': 'Select Date',
      'go': 'Go',
      'reset': 'Reset',
      'inputField': 'Field can not be blank',
    },
  },
};

export {
  cloudClassroomFilterLang
};

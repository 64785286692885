<template lang="pug">
  div.mt-3
    div(align='right')
      font(color='blue') NU : New Upgrade
    div(align='right')
      font(color='red') F : First Class
      |  /
      font(color='orange')  7 : New Student
</template>

<script type="text/javascript">
export default {
  name: 'ShorthandDescription',

  components: {
  },

  data() {
    return {
    };
  },

  methods: {
  },
};
</script>

<style lang="scss">
</style>

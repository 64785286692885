<template lang="pug">
  .row
    .col-md-6
      label {{ $t('class_start_time') }}
      br
      datepicker.mr-2(v-model="filter.classStartDate" :bootstrap-styling='true' format='yyyy-MM-dd' :placeholder="$t('select_date')")
      vue-timepicker(v-model="filter.classStartTime" format="HH:mm" :minute-interval="30" hide-clear-button)
    .col-md-6
      label {{ $t('class_end_time') }}
      br
      datepicker.mr-2(v-model="filter.classEndDate" :bootstrap-styling='true' format='yyyy-MM-dd' :placeholder="$t('select_date')")
      vue-timepicker(v-model="filter.classEndTime" format="HH:mm" :minute-interval="30" hide-clear-button)
    .col-md-3
      br
      .input-group
        button.btn.btn-primary.mr-2(@click="search()") {{ $t('go') }}
        button.btn.btn-default(@click="reset()") {{ $t('reset') }}
</template>

<script type="text/javascript">
import { alertMessage } from '@/utils/sweetAlert.js';
import Datepicker from 'vuejs-datepicker';
import VueTimepicker from 'vue2-timepicker';
import { cloudClassroomFilterLang } from './lang';

export default {
  name: 'CloudClassroomFilter',

  components: {
    Datepicker,
    VueTimepicker,
  },

  i18n: cloudClassroomFilterLang,

  data() {
    return {
      filter: {
        classStartDate: '',
        classStartTime: {
          HH: '00',
          mm: '00',
        },
        classEndDate: '',
        classEndTime: {
          HH: '00',
          mm: '00',
        },
      },
    };
  },

  methods: {
    // 只做資料傳遞
    search(){
      if (this.valueCheck()){
        this.$emit('search', this.filter);
      }
    },
    // 檢查欄位是否填寫
    valueCheck(){
      if (this.filter.classStartDate !== '' && this.filter.classEndDate !== ''){
        return true;
      } else {
        alertMessage(this.$t('failed'), 'error', this.$t('inputField'));
        return false;
      }
    },
    // 重設傳空並初始資料
    reset(){
      this.filter = {
        classStartDate: '',
        classStartTime: {
          HH: '00',
          mm: '00',
        },
        classEndDate: '',
        classEndTime: {
          HH: '00',
          mm: '00',
        },
      };
      this.$emit('search', {});
    },
  },
};
</script>

<style lang="scss">
@import "./cloudClassroom"
</style>

<template lang="pug">
  div(style='width: 200px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;' :title="tableData.material.title")
    div
      span.badge.badge-primary {{ tableData.material.level }}
      a(v-if="tableData.material.file" :href="tableData.material.file") &nbsp;{{ tableData.material.title }}
      span(v-else) &nbsp;{{ tableData.material.title }}
      span.badge(:class="materialNoteClass(tableData.class_type)") {{ materialNoteText(tableData.class_type) }}
</template>

<script type="text/javascript">

export default {
  name: 'MaterialField',

  props: {
    tableData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {

    };
  },

  computed: {

  },

  methods: {
    materialNoteClass(type){
      switch (type) {
        case 3:
          return 'badge-danger';
        case 5:
        case 6:
          return 'badge-warning';
        default:
          return 'd-none';
      }
    },

    materialNoteText(type){
      switch (type) {
        case 3:
          return 25;
        case 5:
          return 10;
        case 6:
          return 'A.T.';
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss">
</style>

import companyConstant from '@/constants/company';
const cloudClassroomTableLang = {
  messages: {
    tw: {
      'no_relevant_data': '查無資料',
      'classroom_title': '教室標題',
      'consultant_status': '顧問狀態',
      'noShow': '未出席',
      'show': '已出席',
      'late': '遲到',
      'tardy': '嚴重遲到',
      'problem': '問題',
      'material': '教材',
      'age': '年齡',
      'note': 'Note',
      'access': '進入',
      'host': '主持',
      'hostBtnTitle': '主持百家書苑',
      'accessBtnTitle': '進入教室',
      'delayGetInMes': '已經超過進入教室時間，如有任何問題請來電' + companyConstant.INFO.CONTACT_PHONE + '，謝謝！',
      'remainTimeMessageSecond': '等等！還要再{time}秒鐘才能進入教室唷！',
      'remainTimeMessageMinute': '等等！還要再{time}分鐘才能進入教室唷！',
      'remainTimeMessageHour': '等等！還要再{time}小時才能進入教室唷！',
      'pagination_info': '正在顯示第 {from} 項到第 {to} 項，共 {total} 項',
    },
    en: {
      'no_relevant_data': 'No Relevant Data',
      'classroom_title': 'Classroom Title',
      'consultant_status': 'Consultant Status',
      'noShow': 'No Show',
      'show': 'Show',
      'late': 'Late',
      'tardy': 'Tardy',
      'problem': 'Problem',
      'material': 'Material',
      'age': 'Age',
      'note': 'Note',
      'access': 'Access',
      'host': 'Host',
      'hostBtnTitle': 'Host the academy talks classroom',
      'accessBtnTitle': 'Access the classroom',
      'delayGetInMes': 'This class is no longer avaliable, please check another classroom.',
      'remainTimeMessageSecond': '{time} seconds left to enter the classroom!！',
      'remainTimeMessageMinute': '{time} minutes left to enter the classroom!！',
      'remainTimeMessageHour': '{time} hours left to enter the classroom!！',
      'pagination_info': 'Displaying {from} to {to} of {total} items',
    },
  },
};

export {
  cloudClassroomTableLang
};

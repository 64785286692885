<template lang="pug">
  div
    .mr-1.d-inline(v-if="vipData.apply_method === 'on_demand'")
      span.badge.badge-dark(v-b-tooltip.hover :title="$t('onTheGo')")  OTG
    .mr-1.d-inline(v-if="vipData.suggest")
      span.badge.badge-danger(v-b-tooltip.hover :title="vipData.suggest")  Note
    .mr-1.d-inline(v-if="vipData.first_class === true")
      span.badge.badge-danger(v-b-tooltip.hover :title="$t('firstClass')")  F
    .mr-1.d-inline(v-if="vipData.seven_days === true")
      span.badge.badge-warning(v-b-tooltip.hover :title="$t('sevenDays')")  7
    .mr-1.d-inline(v-if="vipData.new_upgrade === true && vipData.seven_days === false && vipData.first_class === false")
      span.badge.badge-info(v-b-tooltip.hover :title="$t('newUpgrade')")  NU
    .mr-1.d-inline(v-if="vipData.is_kid === 1")
      span.badge.badge-success(v-b-tooltip.hover :title="$t('kid')")  K
    span.badge.badge-primary(v-b-tooltip.hover :title="$t('level')")  {{ vipData.level }}
    | &nbsp;{{ vipData.english_name }}
</template>

<script type="text/javascript">

export default {
  name: 'VipField',

  props: {
    // eslint-disable-next-line
    vipData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
    };
  },

  computed: {

  },

  methods: {

  },
};
</script>

<style lang="scss">
</style>

<template lang="pug">
  div.mt-3(style="overflow:auto;")
    top-twenty-suggestion-modal
    vuetable(
      ref="cloudClassroomTable"
      :data="classroomData"
      :data-manager="onLoadData"
      :api-mode="false"
      :fields="fields"
      :pre-page="10"
      pagination-path="pagination"
      track-by="id"
      @vuetable:loaded="CheckVipStatus = true"
      @vuetable:loading="CheckVipStatus = false"
      @vuetable:pagination-data="onPaginationData"
    )
      template(slot='ClassroomTitle' slot-scope='props')
        span.badge.badge-pill.badge-primary {{ "Room." + props.rowData.room_id }}
        | &nbsp;{{ props.rowData.title }}
      template(slot='ConsultantStatus' slot-scope='props')
        div {{ getConsultantStatus(props.rowData.consultant_status) }}
      template(slot='MaterialLink' slot-scope='props')
        material-field(:tableData="props.rowData")
      template(slot='VipName' slot-scope='props')
        div(v-if="props.rowData.vip && props.rowData.class_type === 6")
          | {{ props.rowData.vip.length }}
        vip-field(
          v-else-if="props.rowData.vip && props.rowData.vip[0]"
          :vip-data="props.rowData.vip[0]"
        )
        b-button(
          v-if="showTopTwentySuggestionButton(props.rowData.class_type)"
          @click="openTopTwentySuggestionModal({\
            vip: props.rowData.vip[0],\
            classroomId: props.rowData.id,\
            index: props.rowIndex,\
            row: props\
          })"
          variant="success"
          size="sm"
        )
          font-awesome-icon(icon="angle-down")
      template(slot='age' slot-scope='props')
        span(v-if="props.rowData.vip && props.rowData.vip[0]") {{ props.rowData.vip[0].age }}
      template(slot='ClassNote' slot-scope='props')
        div(v-if="showNote(props.rowData.class_type)") {{ props.rowData.note }}
      template(slot='Access' slot-scope='props')
        div(v-if="props.rowData.class_type === 6")
          b-button.btn.btn-success(
            type='button'
            target='_blank'
            :title="$t('hostBtnTitle')"
            @click="HostClassroom(props.rowData.id, props.rowData.access_classroom_token)"
          ) {{ $t('host') }}
        div(v-else)
          b-button.btn.btn-success(
            type='button'
            target='_blank'
            :title="$t('accessBtnTitle')"
            @click="AccessClassroom(props.rowData, currentTime)"
          ) {{ $t('access') }}
    .vuetable-pagination.ui.basic.segment.grid
      vuetable-pagination-info.pagination(
        ref='cloudClassroomPaginationInfo'
        :info-template="$t('pagination_info')"
        :no-data-template="$t('no_relevant_data')"
      )
      vuetable-pagination-bootstrap.float-right(
        ref='cloudClassroomPagination'
        @vuetable-pagination:change-page='onChangePage'
        :css='tableCss.paginationBS4'
      )

</template>

<script type="text/javascript">
import { mapState, mapMutations } from 'vuex';
import { alertMessage } from '@/utils/sweetAlert.js';
import moment from 'moment';
import Vuetable from 'vuetable-2/src/components/Vuetable';
import VuetableCss from '@/components/vueTable/vuetableCss.js';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo';
import VuetablePaginationBootstrap from '@/components/vueTable/vuetablePaginationBootstrap';
import VipField from '@/components/consultant/cloudClassroom/cloudClassroomTable/vipField';
import MaterialField from '@/components/consultant/cloudClassroom/cloudClassroomTable/materialField';
import { cloudClassroomTableLang } from './lang';
// import Vue from 'vue';
import classroomConstants from '@/constants/classroom.js';

import topTwentySuggestionModal, {
  uuid as TOP_TWENTY_SUGGESTION_MODAL_UUID,
} from './collapseTr/index.vue';

export default {
  name: 'CloudClassroomTable',

  components: {
    Vuetable,
    VuetablePaginationBootstrap,
    VuetablePaginationInfo,
    VuetableCss,
    VipField,
    MaterialField,

    topTwentySuggestionModal,
  },

  i18n: cloudClassroomTableLang,

  props: {
    classroomData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentType: {
      type: String,
      default: () => {
        return 'present';
      },
    },
  },

  data() {
    return {
      tableCss: VuetableCss,
    };
  },

  computed: {
    ...mapState({
      currentTime: state => state.common.currentTime,
    }),
    // 就專案已更改 access 只顯示於 1, 2
    fields: function() {
      return [
        {
          name: '__slot:ClassroomTitle',
          title: this.$t('classroom_title'),
          sortField: 'title',
        },
        {
          name: '__slot:ConsultantStatus',
          title: this.$t('consultant_status'),
        },
        {
          name: '__slot:MaterialLink',
          title: this.$t('material'),
        },
        {
          name: '__slot:VipName',
          title: 'VIP',
        },
        {
          name: '__slot:age',
          title: this.$t('age'),
        },
        {
          name: '__slot:ClassNote',
          title: this.$t('note'),
          visible: true,
        },
        {
          name: '__slot:Access',
          title: this.$t('access'),
          visible: true,
        },
      ];
    },
  },

  watch: {
    currentType(value){
      console.log(value);
      if (value === 'present'){
        this.$refs.cloudClassroomTable.showField(6);
      } else {
        this.$refs.cloudClassroomTable.hideField(6);
      }
    },
  },

  methods: {
    ...mapMutations({
      openTopTwentySuggestionModal(commit, payload) {
        commit('modal/setIsOpen', {
          target: TOP_TWENTY_SUGGESTION_MODAL_UUID,
          status: true,
        });
        commit('modal/setPayload', {
          target: TOP_TWENTY_SUGGESTION_MODAL_UUID,
          payload,
        });
      },
    }),
    getConsultantStatus(status){
      switch (status){
        case 0 :
          return this.$t('noShow');
        case 1 :
          return this.$t('show');
        case 2 :
          return this.$t('late');
        case 3 :
          return this.$t('tardy');
        case 4 :
          return this.$t('problem');
        default :
          return 'Empty Status';
      }
    },
    showNote(type){
      return (type === 3 || type === 5 || type === 7 || type === 8);
    },
    HostClassroom(classroomId, classToken){
      window.open(process.env.VUE_APP_BASE_API + '/webex/host_access_webex_classroom?ci=' + classroomId + '&access_token=' + classToken);
    },
    AccessClassroom(classroomData, currentTime){
      // 技術債接取新API直接取得 開始結束進入教室時間
      // const enterOpenTime = moment(classroomData.enter_open_time).format('YYYY-MM-DD HH:mm:ss'),
      //       enterCloseTime = moment(classroomData.enter_close_time).format('YYYY-MM-DD HH:mm:ss');
      const classroomTime = classroomData.class_time;
      const enterOpenTime = moment(classroomTime).subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      const enterCloseTime = moment(classroomTime).add(27, 'm').add(59, 's').add(59, 'ms').format('YYYY-MM-DD HH:mm:ss');
      if (enterOpenTime < currentTime && currentTime < enterCloseTime){ // 判斷是否在可進入教室時段內
        const classRoomUrl = this.getClassRoomUrl(classroomData.id, classroomData.access_classroom_token);
        window.open(classRoomUrl);
      } else if (currentTime > enterCloseTime){ // 超過進入教室時間
        alertMessage(this.$t('failed'), 'error', this.$t('delayGetInMes'));
      } else { // 尚未到可上課時間
        this.estimateEnterClassTime(enterOpenTime, currentTime);
      }
    },
    getClassRoomUrl(classroomId, classToken){
      return process.env.VUE_APP_BASE_API + '/webex/access_webex_classroom?ci=' + classroomId + '&access_token=' + classToken;
    },
    // 計算剩餘進入教室時間並顯示Alert
    estimateEnterClassTime(enterOpenTime, currentTime){
      const enterOpenTimeMMoment = moment(enterOpenTime);
      let remainTime = enterOpenTimeMMoment.diff(currentTime, 'seconds'); // 取得目前時間與上課時間差距(秒)
      if (remainTime < 60) {
        alertMessage(this.$t('wait'), 'info', this.$t('remainTimeMessageSecond', { time: remainTime }));
      } else if (remainTime >= 60 && remainTime < 3600) {
        remainTime = parseInt(remainTime / 60);
        alertMessage(this.$t('wait'), 'info', this.$t('remainTimeMessageMinute', { time: remainTime }));
      } else {
        remainTime = parseInt(remainTime / 3600);
        alertMessage(this.$t('wait'), 'info', this.$t('remainTimeMessageHour', { time: remainTime }));
      }
    },

    onLoadData(sortOrder, pagination){
      const params = {
        sort: sortOrder,
        pageInfo: pagination,
      };
      this.$emit('tableControl', params);
    },

    onChangePage(page) {
      this.$refs.cloudClassroomTable.changePage(page);
    },
    onPaginationData(paginationData) {
      this.$refs.cloudClassroomPaginationInfo.setPaginationData(paginationData);
      this.$refs.cloudClassroomPagination.setPaginationData(paginationData);
    },
    showTopTwentySuggestionButton(classType) {
      // 只有JR的課才要顯示近20堂的評鑑 但這支API沒有給platfrom所以先只用classType去判斷是否顯示
      // 技術債:之後搬家的話記得這裡要從platfrom去看是否顯示
      // jrClassType = [11, 12, 13, 16, 18]
      const jrClassType = [classroomConstants.BOOK_CLASS.CLASS_TYPE_NORMAL, classroomConstants.BOOK_CLASS.CLASS_TYPE_SPECIAL, classroomConstants.BOOK_CLASS.CLASS_TYPE_SPECIFY_MATERIAL, classroomConstants.BOOK_CLASS.JUNIOR_ONE_ON_ONE_DEMO, classroomConstants.BOOK_CLASS.REALTIME_JUNIOR_DEMO];
      return jrClassType.includes(classType);
    },
  },
};
</script>

<style lang="scss">
@import './cloudClassroomTable';
</style>

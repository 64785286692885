const classroomLang = {
  messages: {
    tw: {
      'cloud_classroom': '雲端教室',
      'present_class': '目前教室',
      'today_class': '今日教室',
      'history_class': '教室紀錄',
    },
    en: {
      'cloud_classroom': 'Cloud Classroom',
      'present_class': 'Present Class',
      'today_class': 'Today\'s Class',
      'history_class': 'Class History',
    },
  },
};

export {
  classroomLang
};

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.vipData.apply_method === 'on_demand' ? _c('div', {
    staticClass: "mr-1 d-inline"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "badge badge-dark",
    attrs: {
      "title": _vm.$t('onTheGo')
    }
  }, [_vm._v(" OTG")])]) : _vm._e(), _vm.vipData.suggest ? _c('div', {
    staticClass: "mr-1 d-inline"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "badge badge-danger",
    attrs: {
      "title": _vm.vipData.suggest
    }
  }, [_vm._v(" Note")])]) : _vm._e(), _vm.vipData.first_class === true ? _c('div', {
    staticClass: "mr-1 d-inline"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "badge badge-danger",
    attrs: {
      "title": _vm.$t('firstClass')
    }
  }, [_vm._v(" F")])]) : _vm._e(), _vm.vipData.seven_days === true ? _c('div', {
    staticClass: "mr-1 d-inline"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "badge badge-warning",
    attrs: {
      "title": _vm.$t('sevenDays')
    }
  }, [_vm._v(" 7")])]) : _vm._e(), _vm.vipData.new_upgrade === true && _vm.vipData.seven_days === false && _vm.vipData.first_class === false ? _c('div', {
    staticClass: "mr-1 d-inline"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "badge badge-info",
    attrs: {
      "title": _vm.$t('newUpgrade')
    }
  }, [_vm._v(" NU")])]) : _vm._e(), _vm.vipData.is_kid === 1 ? _c('div', {
    staticClass: "mr-1 d-inline"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "badge badge-success",
    attrs: {
      "title": _vm.$t('kid')
    }
  }, [_vm._v(" K")])]) : _vm._e(), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "badge badge-primary",
    attrs: {
      "title": _vm.$t('level')
    }
  }, [_vm._v(" " + _vm._s(_vm.vipData.level))]), _vm._v(" " + _vm._s(_vm.vipData.english_name))]);

}
var staticRenderFns = []

export { render, staticRenderFns }